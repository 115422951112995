import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import { firebaseAuth } from "../provider/AuthProvider";
import { snackNotification } from "../provider/SnackProvider";
import { LangContext } from "../provider/LanguageProvider";
import { firestoreMethods } from "../firebase/firestoreMethods";

import useContentful from "../hooks/useContentful";

import { PageTitle, StyledButton } from "../components/Customs";
import Spacer from "../components/Spacer";
import Wrapper from "../components/Wrapper";
import LoadingWrapper from "../components/LoadingWrapper";

const Signup = () => {
  const [activeStep, setActiveStep] = useState("signup");
  const [registering, setRegistering] = useState(false);

  const [suErrors, setSuErrors] = useState({});

  const { handleSendVerificationEmail, inputs, setInputs, user, errors } = useContext(firebaseAuth);
  const { language } = useContext(LangContext);

  const fields = useContentful("signup");
  const { triggerSnackbar } = useContext(snackNotification);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const id = localStorage.getItem("temp-values-id");
      if (id) {
        localStorage.removeItem("temp-values-id");
        triggerSnackbar({ message: fields.snackbar });
        navigate(`/results/${id}`);
      } else {
        triggerSnackbar({ message: fields.snackbar });
        navigate(`/`);
      }
    } else {
      setActiveStep("signup");
      setInputs({
        name: "",
        surname: "",
        email: "",
        terms: false,
      });
      setRegistering(false);
    }
  }, [user]);

  const validate = async () => {
    const newErrors = {};
    let formIsValid = true;
    if (!inputs.name || inputs.name.length < 2) {
      newErrors.name = fields.errorName;
      formIsValid = false;
    }
    if (!inputs.surname || inputs.surname.length < 2) {
      newErrors.surname = fields.errorName;
      formIsValid = false;
    }
    if (!inputs.email || inputs.email.length < 2) {
      newErrors.email = fields.errorMail;
      formIsValid = false;
    } else {
      const email = inputs.email.toLowerCase();
      const isExisting = await firestoreMethods.isUserRegistered(email);
      if (isExisting) {
        newErrors.email = fields.errorMailExists;
        formIsValid = false;
      }
    }

    setSuErrors(newErrors);
    return formIsValid;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("handleSubmit");

    setRegistering(true);

    const formIsValid = await validate();
    if (!formIsValid) {
      setRegistering(false);
      return;
    }

    const newUser = {
      name: inputs.name,
      surname: inputs.surname,
      email: inputs.email.toLowerCase(),
      isAdmin: false,
      hasSurvey: false,
      availableTests: 0,
    };

    try {
      const newId = await firestoreMethods.registerUser(newUser);
      if (newId) {
        console.log("new user registered");
        handleSendVerificationEmail(language);
        setActiveStep("registered");
      }
    } catch (error) {
      console.error("Error registering new user:", error);
      triggerSnackbar({ message: fields.errorRegistering });
    }

    setRegistering(false);
  }

  function handleChange(e) {
    const { name, value } = e.target;

    setInputs((prev) => ({ ...prev, [name]: value }));
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <Box maxWidth="500px" mx={"auto"}>
      <Wrapper>
        <PageTitle>{fields.title}</PageTitle>
        {activeStep === "signup" && (
          <form onSubmit={handleSubmit}>
            {/* <Typography component="div">{documentToReactComponents(fields.description)}</Typography> */}
            <Spacer i={2} />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  error={suErrors.name}
                  helperText={suErrors.name}
                  onChange={handleChange}
                  name="name"
                  placeholder={fields.labelName}
                  label={fields.labelName}
                  value={inputs.name || ""}
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={suErrors.surname}
                  helperText={suErrors.surname}
                  onChange={handleChange}
                  name="surname"
                  placeholder={fields.labelSurname}
                  label={fields.labelSurname}
                  value={inputs.surname || ""}
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={suErrors.email}
                  helperText={suErrors.email}
                  onChange={handleChange}
                  name="email"
                  placeholder="email"
                  label="E-Mail"
                  value={inputs.email || ""}
                  variant="standard"
                  fullWidth
                  required
                />
              </Grid>
              {/*  <StyledFormControl>
          <InputLabel>{fields.passwordTitle}</InputLabel>
          <Input
            onChange={handleChange}
            type="password"
            name="password"
            placeholder="password"
            value={inputs.password}
          />
          <FormHelperText>{fields.passwordCaption}</FormHelperText>
        </StyledFormControl> */}
              <Grid item xs={12}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() =>
                          handleChange({
                            target: {
                              name: "terms",
                              value: !inputs.terms,
                            },
                          })
                        }
                        checked={inputs.terms}
                        name="terms"
                      />
                    }
                    label={fields.terms}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Spacer />
            {errors && errors.length > 0 && (
              <Typography variant="body1" color="error">
                {errors[0]}
              </Typography>
            )}
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!inputs.terms || registering}
            >
              {fields.button}
            </StyledButton>
          </form>
        )}
        {activeStep === "registered" && (
          <>
            <Typography component="div">
              {documentToReactComponents(fields.registeredDescription)}
            </Typography>
            <Spacer />
          </>
        )}
      </Wrapper>
    </Box>
  );
};

export default Signup;
