import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { valueData } from "../provider/ValueProvider";
import { StyledButton } from "../components/Customs";
import Spacer from "../components/Spacer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Wrapper from "../components/Wrapper";
import CustomStepper from "../components/CustomStepper";
import { Typography } from "@mui/material";
import { firebaseAuth } from "../provider/AuthProvider";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { Model, Survey } from "survey-react-ui";

import "../ressources/defaultV2.fontless.css";
import { themeJson } from "../config/survey_theme";
function SurveyPage() {
  const navigate = useNavigate();

  const { persistValues, initSurveyResults, setSurveyResults } = useContext(valueData);
  const { user } = useContext(firebaseAuth);

  const fields = useContentful("survey");
  const [showEndVideo, setEndShowVideo] = useState(false);
  const [questions, setQuestions] = useState(null);

  let survey;
  if (questions) {
    survey = new Model(questions);
    survey.locale = "en";
    survey.applyTheme(themeJson);
    survey.onComplete.add(handlePersistAndPush);
  } else {
    survey = null;
  }

  useEffect(() => {
    const loadSurvey = async () => {
      if (user && user.appUser?.survey) {
        console.log("loading survey: ", user.appUser.survey);
        const newSurvey = await firestoreMethods.loadSurvey(user.appUser.survey);
        if (newSurvey) {
          console.log("survey questions: ", newSurvey.questions);
          setQuestions(newSurvey.questions);
        }
      } else {
        console.log("no survey found for user: ", user?.displayName);
      }
    };

    loadSurvey();
    initSurveyResults();
  }, [user]);

  async function handlePersistAndPush(sender, options) {
    const surveyResults = sender.data;
    setSurveyResults(surveyResults);
    console.log("hanlde persist and push");
    console.log("data: ", surveyResults);
    setEndShowVideo(true);
  }

  const handlePersist = async () => {
    console.log("storing result for user: ", user);
    const id = await persistValues(user.uid);
    console.log(`persisted result for the user (${user.uid}) with id: ${id}`);
    navigate(`/results/${id}`);
  };

  if (showEndVideo) {
    return (
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {fields.endVideoTitle}
        </Typography>
        <Spacer />
        {/* <YoutubeEmbed id={fields.endVideoYoutubeId} /> */}
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {documentToReactComponents(fields.endVideoDescription)}
        </Typography>

        <StyledButton variant="contained" color="primary" onClick={handlePersist}>
          {fields.endVideoButton}
        </StyledButton>
      </Wrapper>
    );
  }

  if (!(fields && survey)) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <CustomStepper activeStep={1} />
      <Spacer />
      <Typography variant="h6">{fields.title}</Typography>
      <Spacer />
      <Survey model={survey} onComplete={handlePersistAndPush} />
    </Wrapper>
  );
}

export default SurveyPage;
