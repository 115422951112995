import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Wrapper from "./Wrapper";
import LoadingWrapper from "./LoadingWrapper";
import { firebaseAuth } from "../provider/AuthProvider";

export const ProtectedRoute = ({ children, allowOnlyAdmin = false }) => {
  const { user, isLoading } = useContext(firebaseAuth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && !(user && user.appUser)) {
      // If user is not logged in, redirect to login page
      console.log("not logged in -> redirecting to the login page");
      navigate("/signin", location);
    }
  });

  useEffect(() => {
    // Ensure loading is complete and user exists before checking role
    if (!isLoading) {
      if (user && user.appUser) {
        if (allowOnlyAdmin && !user.isAdmin) {
          navigate("/");
        }
      } else {
        // Redirect to login if not logged in
        console.log("not logged in -> redirecting to the login page");
        navigate("/signin", { state: { from: location } }); // Pass the current location in state to enable redirecting back after login
      }
    }
  }, [isLoading, user, allowOnlyAdmin, navigate, location]);

  // If user is logged in and has the correct role, allow access to route
  return !isLoading ? (
    user &&
    user.appUser &&
    (user.isAdmin || !allowOnlyAdmin || (allowOnlyAdmin && user.isAdmin)) ? (
      children
    ) : (
      <Wrapper />
    )
  ) : (
    <LoadingWrapper />
  );
};
