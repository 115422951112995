import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import { valueData } from "../provider/ValueProvider";
import env from "../env-config";

import { StyledButton } from "../components/Customs";
import Spacer from "../components/Spacer";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Wrapper from "../components/Wrapper";
import CustomStepper from "../components/CustomStepper";
import { Chip, Grid, Grow, Typography } from "@mui/material";
import { LangContext, DEFAULT_LANGUAGE } from "../provider/LanguageProvider";

function Adjust() {
  const navigate = useNavigate();
  const { values, handleUpdate, initialized } = useContext(valueData);

  const { language } = useContext(LangContext);
  const fields = useContentful("adjust");
  const selected = values.filter((e) => e.selected);

  const max = env.adjusted.max;
  const adjusted = values.filter((e) => e.adjusted);
  const progress = (adjusted.length / max) * 100;
  const done = adjusted.length === max;

  function onDelete(value) {
    if (value.adjusted) {
      handleUpdate(value, { adjusted: false });
    }
  }

  function onClick(value) {
    handleUpdate(value, { adjusted: true });
  }

  function disabled(value) {
    return !value.adjusted && adjusted.length >= max;
  }

  function color(value) {
    switch (true) {
      case value.adjusted:
        return "primary";
      case value.selected:
        return "default";
      default:
        return "default";
    }
  }

  function deletable(value) {
    return value.adjusted;
  }

  if (values.length === 0 && initialized) {
    navigate("/start");
  }

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <CustomStepper activeStep={0} />
      <Spacer />
      <Typography variant="h6">{fields.title}</Typography>
      <Spacer />

      <Spacer />
      <Grid container spacing={1}>
        {selected.map((value, index) => {
          const translation = value.value.translations[language]
            ? value.value.translations[language].value
            : value.value.translations[DEFAULT_LANGUAGE].value;
          return (
            <Grid item xs={6} sm={3} key={translation} container>
              <Grow in={true} timeout={100 + index * 20}>
                <Chip
                  disabled={disabled(value)}
                  label={translation}
                  onDelete={
                    deletable(value)
                      ? () => {
                          onDelete(value);
                        }
                      : () => {}
                  }
                  onClick={() => {
                    onClick(value);
                  }}
                  color={color(value)}
                />
              </Grow>
            </Grid>
          );
        })}
      </Grid>
      <Spacer />
      <Spacer />

      <Typography variant="h6">{documentToReactComponents(fields.description)}</Typography>
      <LinearProgressWithLabel value={progress} />
      <StyledButton
        variant="contained"
        color="primary"
        disabled={!done}
        onClick={() => navigate("/focus")}
      >
        {fields.button}
      </StyledButton>
    </Wrapper>
  );
}

export default Adjust;
