import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../components/Wrapper";
import { Box } from "@mui/material";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import env from "../env-config";
import LoadingWrapper from "../components/LoadingWrapper";
import { functions } from "../firebase/firebaseApp";

const CheckoutForm = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const { sid } = useParams();
  const [session, setSession] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPromise = async () => {
      console.log("fetching stripe promise");
      const configStripe = httpsCallable(functions, "api/configStripe");
      configStripe({ testMode: env.dev.payment_test_mode }).then((result) => {
        const { publishableKey } = result.data;
        setStripePromise(loadStripe(publishableKey));
      });
    };

    if (!stripePromise) fetchPromise();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (sid.indexOf("_secret_") > -1) {
        //we've received a client secret from the orders page
        console.log("setting session data for sid: ", sid);
        setSession({ clientSecret: sid });
        return;
      }

      console.log("fetching session data for sid: ", sid);
      const sessionFnc = httpsCallable(functions, "api/session-status");
      const result = await sessionFnc({ testMode: env.dev.payment_test_mode, sessionId: sid });

      const clS = result.data;
      const sessionData = clS.session;

      console.log("received session: ", sessionData);

      if (sessionData && sessionData.client_secret) {
        sessionData.clientSecret = sessionData.client_secret;
        setSession(sessionData);
      } else {
      }

      if (clS.status === "complete") {
        navigate("/completion/session_id=" + sid);
      }

      // setCustomerEmail(clS.customer_email);
    };

    if (!sid) {
      navigate("/orders");
    } else fetchData();
  }, [sid]);

  const handleSubmit = async (event) => {};

  if (!(stripePromise && session && session.clientSecret))
    return <LoadingWrapper title="Loading checkout..." />;

  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        {stripePromise && session && session.clientSecret && (
          <Box sx={{ padding: { xs: "0", sm: "0 2rem" } }}>
            <EmbeddedCheckoutProvider
              key={session.clientSecret}
              stripe={stripePromise}
              options={{ clientSecret: session.clientSecret }}
            >
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </Box>
        )}
      </form>
    </Wrapper>
  );
};

export default CheckoutForm;
