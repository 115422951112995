import React from "react";
import { PageTitle, StyledButton } from "../components/Customs";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Wrapper from "../components/Wrapper";
import { Typography } from "@mui/material";

function NoValues(props) {
  const fields = useContentful("noValues");

  if (!fields) {
    return <LoadingWrapper />;
  }

  return (
    <Wrapper>
      <PageTitle>{fields.title}</PageTitle>

      <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
      <StyledButton variant="contained" href={"/select"}>
        {fields.button}
      </StyledButton>
    </Wrapper>
  );
}

export default NoValues;
