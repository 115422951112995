import { Button, Typography } from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/system";
import { benMainBeige, borderRadiusLarge } from "../theme";

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

export const StyledButtonInverted = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  backgroundColor: "black",
  color: benMainBeige,
  "&:hover": {
    backgroundColor: benMainBeige,
    color: "black",
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
}));

export const StyledButtonValue = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  padding: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(2, 0),
  },
  ...theme.typography.h5,
  border: "1px solid",
  borderRadius: borderRadiusLarge,
  "@media (hover: none)": {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.secondary.main,
      border: "1px solid",
      color: theme.palette.secondary.main,
    },
    "&:active": {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.primary.main,
      border: "1px solid",
      color: theme.palette.primary.main,
      // Reset active styles
    },
  },
}));

export const PageTitle = (props) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Typography variant={isXs ? "h4" : "h3"} align="center">
      {props.children}
    </Typography>
  );
};
