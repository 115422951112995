import { CssBaseline, ThemeProvider } from "@mui/material";

import "./App.css";
import "typeface-lato";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import StartPage from "./pages/StartPage";
import Profile from "./pages/Profile";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Adjust from "./pages/Adjust";
import Select from "./pages/Select";
import Reduce from "./pages/Reduce";
import Focus from "./pages/Focus";
import Result from "./pages/Result";

import SnackProvider from "./provider/SnackProvider";
import Imprint from "./pages/Imprint";
import NoValues from "./pages/NoValues";
import PasswordReset from "./pages/PasswordReset";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Values from "./pages/Values";
import ValueProvider from "./provider/ValueProvider";
import theme from "./theme";
import LangProvider from "./provider/LanguageProvider";
import Users from "./pages/Users";
import SurveyPage from "./pages/SurveyPage";
import Orders from "./pages/Orders";
import OrderCompletion from "./pages/OrderCompletion";
import HomePage from "./pages/HomePage";
import { ProtectedRoute } from "./components/ProtectedRoute";
import CheckoutForm from "./pages/CheckoutForm";

function App() {
  return (
    <LangProvider>
      <ValueProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <SnackProvider>
              <Header />

              <Routes>
                <Route
                  path="/start"
                  element={
                    <ProtectedRoute>
                      <StartPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/imprint" element={<Imprint />} />
                <Route
                  path="/no-values"
                  element={
                    <ProtectedRoute>
                      <NoValues />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/values"
                  element={
                    <ProtectedRoute allowOnlyAdmin={true}>
                      <Values />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/select"
                  element={
                    <ProtectedRoute>
                      <Select />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/reduce"
                  element={
                    <ProtectedRoute>
                      <Reduce />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/adjust"
                  element={
                    <ProtectedRoute>
                      <Adjust />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/focus"
                  element={
                    <ProtectedRoute>
                      <Focus />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/survey"
                  element={
                    <ProtectedRoute>
                      <SurveyPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="/signin" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/order"
                  element={
                    <ProtectedRoute>
                      <Orders />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/checkout/:sid"
                  element={
                    <ProtectedRoute>
                      <CheckoutForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/completion"
                  element={
                    <ProtectedRoute>
                      <OrderCompletion />
                    </ProtectedRoute>
                  }
                />
                <Route path="/results/:id" element={<Result />} />
                <Route
                  path="/password-reset"
                  element={
                    <ProtectedRoute>
                      <PasswordReset />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <ProtectedRoute allowOnlyAdmin={true}>
                      <Users />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<HomePage />} />
              </Routes>

              <Footer />
            </SnackProvider>
          </Router>
        </ThemeProvider>
      </ValueProvider>
    </LangProvider>
  );
}

export default App;
