import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import useContentful from "../hooks/useContentful";
import { StyledButtonInverted } from "../components/Customs";
import { firebaseAuth } from "../provider/AuthProvider";
import LoadingWrapper from "../components/LoadingWrapper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const HomePage = () => {
  const { user } = useContext(firebaseAuth);
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const fields = useContentful("home");

  const handleStartClick = () => {
    if (user?.appUser) {
      navigate("/start");
    } else {
      navigate("/signin");
    }
  };

  if (!fields) {
    return <LoadingWrapper title={"Loading Application..."} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          xs: "center", // Center the button on extra-small screens
          sm: "flex-start", // Align to the start on small screens and above
        },
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: {
          xs: 0, // paddingLeft for extra-small screens
          sm: "1.25em", // paddingLeft for small screens and above
        },
        paddingRight: {
          xs: 0, // paddingRight for extra-small screens
          sm: "1.25em", // paddingRight for small screens and above
        },
      }}
    >
      <Box
        sx={{
          marginTop: "1.5em",
          marginBottom: "5em",
        }}
      >
        <Typography variant={isXs ? "h2" : "h1"} sx={{ mt: 2 }}>
          {fields.title}
        </Typography>
        <Typography variant={isXs ? "h5" : "h2"} sx={{ mt: 2 }}>
          {fields.subtitle}
        </Typography>
        <Typography component="div" variant="body1" fontSize={"1.2em"}>
          {documentToReactComponents(fields.description)}
        </Typography>
      </Box>
      <Box>
        <StyledButtonInverted
          sx={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}
          onClick={handleStartClick}
        >
          {fields.buttonStart}
        </StyledButtonInverted>
      </Box>
    </Box>
  );
};

export default HomePage;
