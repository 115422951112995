import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../components/LoadingWrapper";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { firebaseAuth } from "../provider/AuthProvider";

import Spacer from "../components/Spacer";

import formatDateTime from "../lib/formatDateTime";
import StarEvaluation from "../components/StarEvaluation";
import Wrapper from "../components/Wrapper";
import useContentful from "../hooks/useContentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PageTitle, StyledButton } from "../components/Customs";
import { formatString } from "../lib/formatString";

const StyledAccordionDetails = styled(AccordionDetails)`
  display: block;
`;

const Profile = () => {
  const [resultsData, setResultsData] = useState(null);
  const { user } = useContext(firebaseAuth);
  const fields = useContentful("profile");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        return;
      }
      const newData = await firestoreMethods.getResultsForUser(user);
      setResultsData(newData);
    };

    fetchData();
  }, [user]);

  if (!user || !resultsData || !fields) {
    return <LoadingWrapper />;
  }

  return (
    <React.Fragment>
      <Wrapper minheight="150px">
        <PageTitle>Hallo, {user.appUser.name}</PageTitle>
        <Spacer i={2} />
        {!!user.appUser.availableTests && user.appUser.availableTests > 0 && (
          <Typography variant="body1">
            {formatString(fields.descriptionAvailableTests, [
              user.appUser.availableTests,
              user.appUser.availableTests === 1 ? "" : "s",
            ])}
          </Typography>
        )}
        {!user.appUser.availableTests && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" component={"div"}>
              {fields.descriptionNoTestsAvailable}
            </Typography>
            <StyledButton
              variant="contained"
              href="/order"
              color="primary"
              sx={{ marginLeft: "2rem" }}
            >
              {fields.buttonTestsUnavailable}
            </StyledButton>
          </Box>
        )}
        <Spacer />
      </Wrapper>
      <Wrapper>
        <PageTitle>{fields.title}</PageTitle>
        <Typography component="div">{documentToReactComponents(fields.description)}</Typography>
        <Spacer />
        {resultsData.map(({ id, data }) => (
          <Accordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography component="div">{formatDateTime(data.createdAt.seconds)}</Typography>
            </AccordionSummary>
            <StyledAccordionDetails display="block">
              <StarEvaluation focusedValues={data.values} />
              <Button onClick={() => navigate(`/results/${id}`)} variant="contained">
                {fields.buttonResultPage}
              </Button>
            </StyledAccordionDetails>
          </Accordion>
        ))}
      </Wrapper>
    </React.Fragment>
  );
};

export default Profile;
