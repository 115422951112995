import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { functions } from "../firebase/firebaseApp";
import { firebaseAuth } from "../provider/AuthProvider";
import Wrapper from "../components/Wrapper";
import useContentful from "../hooks/useContentful";
import LoadingWrapper from "../components/LoadingWrapper";
import Spacer from "../components/Spacer";
import { Typography } from "@mui/material";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { PageTitle, StyledButton } from "../components/Customs";
import { firestoreMethods } from "../firebase/firestoreMethods";
import env from "../env-config";

const OrderCompletion = () => {
  const [session, setSession] = useState(null);

  const { user } = useContext(firebaseAuth);

  const location = useLocation();
  const { search } = location;

  const fields = useContentful("orderComplete");

  useEffect(() => {
    const loadSessionData = async () => {
      if (!(user && search)) return;

      const urlParams = new URLSearchParams(search);
      const sessionId = urlParams.get("session_id");
      const sessionData = await firestoreMethods.loadSession(sessionId);
      console.log("loaded session data: ", sessionData);

      const sessionFnc = httpsCallable(functions, "api/session-status");
      sessionFnc({ testMode: env.dev.payment_test_mode, sessionId }).then(
        (result) => {
          const clS = result.data;

          if (clS.status === "complete") {
            const qty = sessionData.cart[0].quantity || 0;

            const availableTests = user.appUser.availableTests || 0;
            firestoreMethods.updateUser(user.id, {
              availableTests: availableTests + qty,
            });
          }

          firestoreMethods.deleteSession(sessionId);

          setSession({ status: clS.status, id: sessionId });
          // setCustomerEmail(clS.customer_email);
        },
        (error) => {
          console.log("Error fetching session status: ", error);
        }
      );
    };

    loadSessionData();
  }, [search, user]);

  const redirectToCheckout = () => {
    return session && session.id && session.status !== "complete";
  };

  if (!fields || !(session && session.status)) {
    return <LoadingWrapper />;
  }

  const getSessionComplete = () => {
    return (
      <>
        <PageTitle>{fields.title}</PageTitle>
        <Spacer />
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {documentToReactComponents(fields.description)}
        </Typography>
        <Spacer />
        <StyledButton variant="contained" href="/select" color="primary">
          {fields.buttonContinue}
        </StyledButton>
      </>
    );
  };

  const getSessionFailed = () => {
    return (
      <>
        <PageTitle>{fields.title}</PageTitle>
        <Spacer />
        <Spacer />
        <Typography variant="h6" gutterBottom>
          {documentToReactComponents(fields.descriptionFailed)}
        </Typography>
        <Spacer />
        <StyledButton
          variant="contained"
          href={redirectToCheckout() ? `/checkout/${session.id}` : "/order"}
          color="primary"
        >
          {fields.buttonFailed}
        </StyledButton>
      </>
    );
  };

  const getSessionStatus = () => {
    switch (session?.status) {
      case "complete":
        return getSessionComplete();
      default:
        return getSessionFailed();
    }
  };

  return <Wrapper>{getSessionStatus()}</Wrapper>;
};

export default OrderCompletion;
