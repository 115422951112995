import React, { useContext, useEffect, useState, useMemo } from "react";
import { firebaseAuth } from "../provider/AuthProvider";
import { firestoreMethods } from "../firebase/firestoreMethods";
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../provider/LanguageProvider";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { EnhancedTableHead } from "../components/enhanced-table";
import { getComparator } from "../types/tables";
import { isEqual } from "lodash";
import { Value } from "../types/values";

const headCells = [
  {
    id: "value",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sortable: true,
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
    sortable: false,
  },
];

function Values() {
  const [values, setValues] = useState(new Map());
  const [searchValue, setSearchValue] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("value");
  const [selectedValue, setSelectedValue] = useState(null);

  const { user } = useContext(firebaseAuth);

  const isAdmin = user !== null && user !== undefined && user.isAdmin;

  useEffect(() => {
    async function loadValues() {
      const newValues = await firestoreMethods.loadValues();
      setValues(newValues);
    }

    loadValues();
  }, []);

  const handleAddValue = async () => {
    if (selectedValue) {
      const val = selectedValue.id !== "new_id" ? values.get(selectedValue.id) : null;
      console.log("val: ", val);
      console.log("selectedValue: ", selectedValue);
      if (!isEqual(val, selectedValue)) {
        const conf = window.confirm(
          "The selected value has been changed! Press OK if you want to discard the changes."
        );
        if (!conf) {
          return;
        }
      }
    }

    const newValue = new Value();
    newValue.translations[DEFAULT_LANGUAGE] = {
      value: "",
      info: "",
      warning: "",
    };
    setSelectedValue(newValue);
  };

  const handleDelete = async (e, valueId) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this value?")) {
      await firestoreMethods.deleteValue(valueId);
      const newValues = new Map(values);
      newValues.delete(valueId);
      setValues(newValues);
      setSelectedValue(null);
      //   if (selectedValue && selectedValue.getId() === valueId) {
      //     setSelectedValue(null);
      //   }
    }
  };

  const handleSelectValue = async (valueId) => {
    if (valueId) {
      const value = values.get(valueId).clone();
      console.log("same object: ", value === values.get(valueId));
      if (value) {
        if (selectedValue && selectedValue.id !== valueId) {
          const val = selectedValue.id !== "new_id" ? values.get(selectedValue.id) : null;

          if (!isEqual(val, selectedValue)) {
            const conf = window.confirm(
              "The selected value has been changed! Press OK if you want to discard the changes."
            );
            if (!conf) {
              return;
            }
          }
        }
        console.log("selected value: ", value);
        setSelectedValue(value);
      }
    }
  };

  const handleChangeValue = async (e) => {
    const name = e.target.name.split("_");
    console.log("target name: ", name);
    const field = name[0];
    const lang = name[1];
    const value = e.target.value;

    if (!(field && lang)) {
      return;
    }

    if (selectedValue) {
      const newSelectedValue = selectedValue.clone();
      console.log("same object: ", newSelectedValue === selectedValue);
      newSelectedValue.changeTranslation(lang, field, value);
      console.log("setting new value: ", newSelectedValue);
      setSelectedValue(newSelectedValue);
    }
  };

  const handleSave = async () => {
    if (selectedValue) {
      const newValue = await firestoreMethods.storeValue(selectedValue);
      const newValues = new Map(values);
      newValues.set(newValue.id, newValue);
      setValues(newValues);
      setSelectedValue(null);
    }
  };

  const handleSearchValueChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    const value1 = a.translations[DEFAULT_LANGUAGE];
    const value2 = b.translations[DEFAULT_LANGUAGE];

    // console.log("a1: ");
    // console.log(value1);

    // console.log("a2: ");
    // console.log(value2);

    // console.log("orderBy: " + orderBy);

    if (value1 && value2) {
      let res = 0;
      switch (orderBy) {
        case "value":
          if (value2.value < value1.value) {
            res = -1;
          }
          if (value2.value > value1.value) {
            res = 1;
          }

          return res;

        default:
          break;
      }
    } else {
      if (value1 && !value2) {
        return -1;
      } else if (!value1 && value2) {
        return 1;
      }
    }
    return 0;
  }

  const filteredValues = useMemo(() => {
    return [...values.values()].filter((value) => {
      return (
        searchValue.length < 3 ||
        (value.translations[DEFAULT_LANGUAGE] &&
          value.translations[DEFAULT_LANGUAGE].value
            .toLowerCase()
            .includes(searchValue.toLowerCase()))
      );
    });
  }, [searchValue, values]);

  const visibleRows = useMemo(
    () => filteredValues.slice().sort(getComparator(order, orderBy, descendingComparator)),
    [order, orderBy, filteredValues]
  );

  const handleTestClick = async () => {
    const newValues = await firestoreMethods.loadValues();
    const data = [];
    console.log("keys:", newValues.keys());
    newValues.keys().forEach((key) => {
      const value = newValues.get(key);
      data.push(value.toFirestore());
    });

    const content = JSON.stringify(data);
    const blob = new Blob([content], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "values.json";
    a.click();

    console.log("data: ", data);
  };

  return (
    <Box
      sx={{
        margin: "1.5rem",
        marginTop: "1rem",
      }}
    >
      <Typography variant="h5" fontWeight={"bold"}>
        Values
      </Typography>
      <Box
        sx={{
          marginTop: "1rem",
        }}
      >
        {isAdmin && (
          <Box>
            <Box display={"flex"} sx={{ gap: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleAddValue}>
                Add New Value
              </Button>
              <Button variant="outlined" color="primary" onClick={handleTestClick}>
                Test
              </Button>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Search..."
                value={searchValue}
                onChange={handleSearchValueChange}
                sx={{ minWidth: "250px" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "1rem",
                minHeight: "400px",
                height: "calc(100vh - 310px)",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  flexBasis: "0",
                  maxWidth: `calc(100% - 450px)`,
                  marginRight: "8px",
                }}
              >
                <TableContainer component={Paper} sx={{ height: "100%" }}>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <EnhancedTableHead
                      numSelected={0}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={() => {}}
                      onRequestSort={handleRequestSort}
                      rowCount={visibleRows.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows.map((value) => {
                        return (
                          <TableRow
                            key={`row-${value.id}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => handleSelectValue(value.id)}
                            className={selectedValue?.id === value.id ? "selected" : ""}
                          >
                            <TableCell scope="row" size="small">
                              {value.translations[DEFAULT_LANGUAGE]?.value || "No name"}
                            </TableCell>

                            <TableCell align="right" style={{ width: 40 }} size="small">
                              <Tooltip title="Delete">
                                <IconButton size="small" onClick={(e) => handleDelete(e, value.id)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              {/* ------------- Value Details ------------- */}
              <Box sx={{ width: "48%" }}>
                {selectedValue && (
                  <Card>
                    <CardHeader
                      title={selectedValue.translations[DEFAULT_LANGUAGE]?.value || "New Value"}
                    />
                    <CardContent>
                      {SUPPORTED_LANGUAGES.map((lang) => {
                        return (
                          <React.Fragment key={lang + "-section"}>
                            <Divider textAlign="left" id={`${lang}-divider`}>
                              {lang.toUpperCase()}
                            </Divider>
                            <TextField
                              fullWidth
                              label={"Value"}
                              name={"value_" + lang}
                              variant="outlined"
                              size="small"
                              value={selectedValue.translations[lang]?.value || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 1, mb: 1 }}
                            />
                            <TextField
                              fullWidth
                              label={"Info"}
                              name={"info_" + lang}
                              variant="outlined"
                              size="small"
                              value={selectedValue.translations[lang]?.info || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 1, mb: 1 }}
                            />
                            <TextField
                              fullWidth
                              label={"Warnung"}
                              name={"warning_" + lang}
                              variant="outlined"
                              size="small"
                              multiline
                              rows={4}
                              value={selectedValue.translations[lang]?.warning || ""}
                              onChange={handleChangeValue}
                              sx={{ mt: 1, mb: 3 }}
                            />
                          </React.Fragment>
                        );
                      })}
                    </CardContent>
                    <CardActions sx={{ padding: 2 }}>
                      <Box flexGrow={1}></Box>
                      <Button variant="contained" sx={{ mr: 2 }} onClick={handleSave}>
                        Save
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setSelectedValue(null);
                        }}
                        sx={{ mr: 2 }}
                      >
                        Cancel
                      </Button>
                    </CardActions>
                  </Card>
                )}
              </Box>
            </Box>
          </Box>
        )}
        {!isAdmin && (
          <>
            <p>This is a protected page. Only users with the admin role can access it.</p>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Values;
