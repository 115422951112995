import { useContext, useState } from "react";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { firebaseAuth } from "../provider/AuthProvider";
import { snackNotification } from "../provider/SnackProvider";
import logo from "../images/ben-logo1.2blk.png";
import { AppBar, Box, Button, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { LangContext, SUPPORTED_LANGUAGES } from "../provider/LanguageProvider";
import { languageMappping } from "../provider/LanguageProvider";
import { StyledButton } from "./Customs";

const StyledA = styled("a")(({ theme }) => ({
  flexGrow: 0,
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.secondary.contrastText,
  "&:active, &:visited, &:link": {
    textDecoration: "none",
    color: theme.palette.secondary.contrastText,
  },
}));

const StyledImg = styled("img")(({ theme }) => ({
  width: "88px",
  maxWidth: "200%",
  marginTop: "10px",
  marginBottom: "10px",
}));

function Header() {
  const { handleSignout } = useContext(firebaseAuth);
  const { triggerSnackbar } = useContext(snackNotification);
  const { token, user } = useContext(firebaseAuth);
  const authed = token !== null;

  const [anchorLanguage, setAnchorLanguage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const isMdOrHigher = useMediaQuery(theme.breakpoints.up("md"));

  const { language, setLanguage } = useContext(LangContext);

  const navigate = useNavigate();

  const handleLanguageClick = (event) => {
    setAnchorLanguage(event.currentTarget);
  };

  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };

  const handleMenu = (event) => {
    console.log(user);

    setAnchorEl(event.currentTarget);
  };

  const handleCloseClick = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    window.location.href = "/profile";
  };

  const handleValuesClick = () => {
    window.location.href = "/values";
  };

  const handleUsersClick = () => {
    window.location.href = "/users";
  };

  function handleSignoutClick() {
    if (handleSignout()) {
      console.log("user signed out");
      triggerSnackbar({ message: "Logout erfolgreich!" });
      navigate("/signin");
    }
  }

  const handleLanguageChange = (event, newLanguage) => {
    if (newLanguage) {
      setLanguage(newLanguage);
      setAnchorLanguage(null);
    }
  };

  return (
    <AppBar position="static" color="primary">
      <Toolbar sx={{ minHeight: "44px" }}>
        <StyledA href="/">
          <StyledImg src={logo} />
        </StyledA>
        <Box sx={{ flexGrow: 1 }} />
        <StyledButton
          variant="outlined"
          startIcon={
            <img
              src={`https://flagcdn.com/w40/${languageMappping[language]}.png`}
              alt={language}
              style={{
                width: "24px",
                height: "24px",
                objectFit: "contain",
              }}
            />
          }
          onClick={handleLanguageClick}
          className="language-switcher"
        >
          {language}
        </StyledButton>
        <Menu
          id="language-menu"
          anchorEl={anchorLanguage}
          keepMounted
          open={!!anchorLanguage}
          onClose={handleCloseLanguage}
        >
          {SUPPORTED_LANGUAGES.map((lang) => (
            <MenuItem key={lang} onClick={(event) => handleLanguageChange(event, lang)}>
              <img
                src={`https://flagcdn.com/w40/${languageMappping[lang]}.png`}
                alt={lang}
                style={{ width: "24px", height: "24px", objectFit: "contain", marginRight: "8px" }}
              />
              {lang.toLocaleUpperCase()}
            </MenuItem>
          ))}
        </Menu>
        {!authed && (
          <Button variant="outlined" href="/signin" color="primary">
            Login
          </Button>
        )}
        {!authed && isMdOrHigher && (
          <Button variant="outlined" href="/signup" color="primary">
            Signup
          </Button>
        )}
        {authed && (
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ p: 0 }}
            >
              <AccountCircleIcon sx={{ width: "36px", height: "36px" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleCloseClick}
            >
              <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
              {user && user.isAdmin && <MenuItem onClick={handleValuesClick}>Values</MenuItem>}
              {user && user.isAdmin && <MenuItem onClick={handleUsersClick}>Users</MenuItem>}
              <MenuItem onClick={handleSignoutClick}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Header;
