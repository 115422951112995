import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import useAnalytics from "../hooks/useAnalytics";
import { Paper } from "@mui/material";
import { styled } from "@mui/system";

export const StyledPaper = styled(Paper)(({ theme, minheight }) => ({
  minWidth: "320px",
  maxWidth: "1024px",
  boxShadow: "none",
  margin: theme.spacing(1, "auto"),
  padding: theme.spacing(1),
  minHeight: minheight || "300px",
  borderRadius: "15px",
  border: "1px solid",
  borderColor: "black",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    margin: theme.spacing(1, "auto"),
    padding: theme.spacing(2),
    minHeight: minheight || "500px",
  },
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(1, 2),
    width: "calc(100% - 32px)",
  },
}));

function Wrapper(props) {
  useAnalytics();
  const location = useLocation();

  //console.log("location", location);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <StyledPaper minheight={props.minheight}> {props.children}</StyledPaper>;
}

export default Wrapper;
